import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _615f5933 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "" */))
const _074089e0 = () => interopDefault(import('../pages/authorization/index.vue' /* webpackChunkName: "pages/authorization/index" */))
const _43bd3a01 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _7dccc4c2 = () => interopDefault(import('../pages/partner/index.vue' /* webpackChunkName: "pages/partner/index" */))
const _51c56540 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _15488350 = () => interopDefault(import('../pages/registration/index.vue' /* webpackChunkName: "pages/registration/index" */))
const _17e957b8 = () => interopDefault(import('../pages/request-password/index.vue' /* webpackChunkName: "pages/request-password/index" */))
const _59bfbf87 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _4944b3da = () => interopDefault(import('../pages/success-payment/index.vue' /* webpackChunkName: "pages/success-payment/index" */))
const _404f0800 = () => interopDefault(import('../pages/marketplace/account/index.vue' /* webpackChunkName: "pages/marketplace/account/index" */))
const _c64f26c8 = () => interopDefault(import('../pages/marketplace/registration/index.vue' /* webpackChunkName: "pages/marketplace/registration/index" */))
const _135c3498 = () => interopDefault(import('../pages/partner/activations-history/index.vue' /* webpackChunkName: "pages/partner/activations-history/index" */))
const _2d3faae6 = () => interopDefault(import('../pages/partner/price-regulator/index.vue' /* webpackChunkName: "pages/partner/price-regulator/index" */))
const _5a6e3368 = () => interopDefault(import('../pages/partner/sim-card/index.vue' /* webpackChunkName: "pages/partner/sim-card/index" */))
const _7e37b960 = () => interopDefault(import('../pages/partner/statistic/index.vue' /* webpackChunkName: "pages/partner/statistic/index" */))
const _44b13c3c = () => interopDefault(import('../pages/partner/withdrawal-funds/index.vue' /* webpackChunkName: "pages/partner/withdrawal-funds/index" */))
const _fe03a0f6 = () => interopDefault(import('../pages/profile/history/index.vue' /* webpackChunkName: "pages/profile/history/index" */))
const _ab4274a4 = () => interopDefault(import('../pages/profile/loyalty-program/index.vue' /* webpackChunkName: "pages/profile/loyalty-program/index" */))
const _7e5a64ce = () => interopDefault(import('../pages/profile/pay/index.vue' /* webpackChunkName: "pages/profile/pay/index" */))
const _c073bb4e = () => interopDefault(import('../pages/profile/referral-program/index.vue' /* webpackChunkName: "pages/profile/referral-program/index" */))
const _52423089 = () => interopDefault(import('../pages/profile/sale-agreement/index.vue' /* webpackChunkName: "pages/profile/sale-agreement/index" */))
const _2c6ba168 = () => interopDefault(import('../pages/profile/settings/index.vue' /* webpackChunkName: "pages/profile/settings/index" */))
const _29ab83e8 = () => interopDefault(import('../pages/profile/statistics/index.vue' /* webpackChunkName: "pages/profile/statistics/index" */))
const _1c912e00 = () => interopDefault(import('../pages/profile/tariffs/index.vue' /* webpackChunkName: "pages/profile/tariffs/index" */))
const _7fed2e18 = () => interopDefault(import('../pages/signup/confirm-email.vue' /* webpackChunkName: "pages/signup/confirm-email" */))
const _1286d9c8 = () => interopDefault(import('../pages/marketplace/account/adding-product/index.vue' /* webpackChunkName: "pages/marketplace/account/adding-product/index" */))
const _2b4f628d = () => interopDefault(import('../pages/marketplace/account/balance/index.vue' /* webpackChunkName: "pages/marketplace/account/balance/index" */))
const _5a4b2bcb = () => interopDefault(import('../pages/marketplace/registration/originalIndex.vue' /* webpackChunkName: "pages/marketplace/registration/originalIndex" */))
const _420698b9 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _c804d0b4 = () => interopDefault(import('../pages/bot-link/_link.vue' /* webpackChunkName: "pages/bot-link/_link" */))
const _72563905 = () => interopDefault(import('../pages/confirm/_token.vue' /* webpackChunkName: "pages/confirm/_token" */))
const _b4b031d0 = () => interopDefault(import('../pages/countries/_.vue' /* webpackChunkName: "pages/countries/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ru",
    components: {
      default: _615f5933
    },
    name: "index___ru"
  }, {
    path: "/cn",
    components: {
      default: _615f5933
    },
    name: "index___cn"
  }, {
    path: "/tr",
    components: {
      default: _615f5933
    },
    name: "index___tr"
  }, {
    path: "/authorization",
    component: _074089e0,
    pathToRegexpOptions: {"strict":true},
    name: "authorization"
  }, {
    path: "/blog",
    component: _43bd3a01,
    pathToRegexpOptions: {"strict":true},
    name: "blog"
  }, {
    path: "/partner",
    component: _7dccc4c2,
    pathToRegexpOptions: {"strict":true},
    name: "partner"
  }, {
    path: "/profile",
    component: _51c56540,
    pathToRegexpOptions: {"strict":true},
    name: "profile"
  }, {
    path: "/registration",
    component: _15488350,
    pathToRegexpOptions: {"strict":true},
    name: "registration"
  }, {
    path: "/request-password",
    component: _17e957b8,
    pathToRegexpOptions: {"strict":true},
    name: "request-password"
  }, {
    path: "/search",
    component: _59bfbf87,
    pathToRegexpOptions: {"strict":true},
    name: "search"
  }, {
    path: "/success-payment",
    component: _4944b3da,
    pathToRegexpOptions: {"strict":true},
    name: "success-payment"
  }, {
    path: "/marketplace/account",
    component: _404f0800,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account"
  }, {
    path: "/marketplace/registration",
    component: _c64f26c8,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-registration"
  }, {
    path: "/partner/activations-history",
    component: _135c3498,
    pathToRegexpOptions: {"strict":true},
    name: "partner-activations-history"
  }, {
    path: "/partner/price-regulator",
    component: _2d3faae6,
    pathToRegexpOptions: {"strict":true},
    name: "partner-price-regulator"
  }, {
    path: "/partner/sim-card",
    component: _5a6e3368,
    pathToRegexpOptions: {"strict":true},
    name: "partner-sim-card"
  }, {
    path: "/partner/statistic",
    component: _7e37b960,
    pathToRegexpOptions: {"strict":true},
    name: "partner-statistic"
  }, {
    path: "/partner/withdrawal-funds",
    component: _44b13c3c,
    pathToRegexpOptions: {"strict":true},
    name: "partner-withdrawal-funds"
  }, {
    path: "/profile/history",
    component: _fe03a0f6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-history"
  }, {
    path: "/profile/loyalty-program",
    component: _ab4274a4,
    pathToRegexpOptions: {"strict":true},
    name: "profile-loyalty-program"
  }, {
    path: "/profile/pay",
    component: _7e5a64ce,
    pathToRegexpOptions: {"strict":true},
    name: "profile-pay"
  }, {
    path: "/profile/referral-program",
    component: _c073bb4e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-referral-program"
  }, {
    path: "/profile/sale-agreement",
    component: _52423089,
    pathToRegexpOptions: {"strict":true},
    name: "profile-sale-agreement"
  }, {
    path: "/profile/settings",
    component: _2c6ba168,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings"
  }, {
    path: "/profile/statistics",
    component: _29ab83e8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-statistics"
  }, {
    path: "/profile/tariffs",
    component: _1c912e00,
    pathToRegexpOptions: {"strict":true},
    name: "profile-tariffs"
  }, {
    path: "/signup/confirm-email",
    component: _7fed2e18,
    pathToRegexpOptions: {"strict":true},
    name: "signup-confirm-email"
  }, {
    path: "/marketplace/account/adding-product",
    component: _1286d9c8,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account-adding-product"
  }, {
    path: "/marketplace/account/balance",
    component: _2b4f628d,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account-balance"
  }, {
    path: "/marketplace/registration/originalIndex",
    component: _5a4b2bcb,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-registration-originalIndex"
  }, {
    path: "/blog/:slug",
    component: _420698b9,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug"
  }, {
    path: "/bot-link/:link?",
    component: _c804d0b4,
    pathToRegexpOptions: {"strict":true},
    name: "bot-link-link"
  }, {
    path: "/confirm/:token?",
    component: _72563905,
    pathToRegexpOptions: {"strict":true},
    name: "confirm-token"
  }, {
    path: "/countries/*",
    component: _b4b031d0,
    pathToRegexpOptions: {"strict":true},
    name: "countries-all"
  }, {
    path: "/*",
    component: _615f5933,
    pathToRegexpOptions: {"strict":true},
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
